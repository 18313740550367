import React from "react";

const DEEP_BLUE = "rgb(2, 64, 112)";

const TieredTitleBar = () => {
  return (
    <div id="ttb">
      <div
        style={{ height: "10px", width: "100%", backgroundColor: DEEP_BLUE }}
      />
      <div
        style={{
          height: "15px",
          width: "97.5%",
          margin: "auto",
          backgroundColor: DEEP_BLUE,
        }}
      />
      <div
        style={{
          width: "95%",
          margin: "auto",
          backgroundColor: DEEP_BLUE,
          textAlign: "center",
          color: "white",
        }}
      >
        <p
          style={{
            textShadow: "3px 3px 1px #000000",
            fontFamily: "Times New Roman",
            fontSize: "3.8vh",
            fontWeight: "bold",

            paddingTop: "10px",
            paddingBottom: "0px",
          }}
        >
          HISTORIC ARDSLEY
        </p>

        <h2 style={{ lineHeight: "2.0rem", padding: "0px 20px 10px 20px" }}>
          <em
            style={{
              fontSize: "16px",
              fontWeight: "300",
            }}
          >
            An elegant Troy apartment residence boutique in the heart of New
            York's Capital Region
          </em>
        </h2>
      </div>
    </div>
  );
};

export default TieredTitleBar;

const routes = [
  { title: "Welcome", href: "/" },
  { title: "About", href: "/about" },
  { title: "Gallery", href: "/gallery" },
  { title: "Location", href: "/location" },
  { title: "Features", href: "/features" },
  { title: "Pricing", href: "/pricing" },
  { title: "Inquire", href: "/inquire" },
];

export default routes;
